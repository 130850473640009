import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";

import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const BlogPage = () => {
	const blogPosts = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
				}
				allWpPost(sort: { order: DESC, fields: date }) {
					nodes {
						id
						slug
						title
						excerpt
						image {
							featuredImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											placeholder: BLURRED
											layout: CONSTRAINED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	return (
		<Layout>
			<GatsbySeo
				title="Our commerical photography blog | Sarah Bennett Commercial Photography"
				description="Read our commerical photography blog including a monthly Behind the Lens reviews!"
				language="en"
				canonical="https://www.sarah-bennett.com/blog"
				openGraph={{
					url: "https://www.sarah-bennett.com/blog",
					title:
						"Our commericial photography blog | Sarah Bennett Commercial Photography",
					description:
						"Read our commerical photography blog including a monthly Behind the Lens reviews!",
					images: [
						{
							url: `${blogPosts.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Our commerical photography blog",
						},
					],
				}}
			/>
			<section className="mb-7">
				<Container>
					<Row className="my-5">
						<Col>
							<h1 className="text-center">Our Blog Articles</h1>
							<hr className="bg-primary w-25 mx-auto" style={{ opacity: 1 }} />
						</Col>
					</Row>
					<Row className="align-items-start">
						{blogPosts.allWpPost.nodes.map((post) => (
							<Col lg={4} md={6} key={post.id} className="px-3 my-3">
								<div>
									<Card className="border-0">
										<Card.Img
											as={GatsbyImage}
											style={{ height: "250px" }}
											image={
												post.image.featuredImage.localFile.childImageSharp
													.gatsbyImageData
											}
										/>
										<Card.Body>
											<Card.Title>{post.title}</Card.Title>
											<Link className="card-link" to={`/blog/${post.slug}`}>
												Read More
											</Link>
										</Card.Body>
									</Card>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPage;
